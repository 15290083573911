<template>
    <div>
        <ion-card :class="{ 'relative-item-not-active': !activePatient }">
            <ion-card-header :class="{ 'pb-0': !activePatient }">
                <ion-card-title padding>{{ completeName }}</ion-card-title>
            </ion-card-header>
            <ion-card-content v-if="!pendingPatient">
                <div class="d-flex align-items-center mb-2">
                    <IconHospital class="text-success mr-1" />
                    <strong>
                        {{
                            activePatient.unit.emergencyRoom
                                ? $t("relativesListPage.emergencyRoom")
                                : $t("relativesListPage.unit")
                        }}:
                    </strong>
                    <span class="ml-2"> {{ activePatient.unit.name }}</span>
                </div>

                <div>
                    <ion-button
                        color="success"
                        class="w-100"
                        @click="relativeView"
                    >
                        {{ $t("view") }}
                    </ion-button>
                </div>
            </ion-card-content>
            <ion-card-content v-else>
                <i>{{ $t("relativesListPage.waitingActivation") }}</i>
            </ion-card-content>
        </ion-card>
    </div>
</template>

<script lang="ts" src="./RelativeItem.ts"></script>
<style lang="scss" src="./RelativeItem.scss"></style>
