import { Options, Vue } from 'vue-class-component';
import IconHospital from '@/views/components/icons/IconHospital.vue';
import IconHeart from '@/views/components/icons/IconHeart.vue';
import { SET_SELECTED_PATIENT } from '@/core/store/types/action-types';
import { Patient, PendingPatient } from '@/../client-generator/generated-clients/api-clients';

@Options({
    name: 'RelativeItem',
    components: {
        IconHospital,
        IconHeart,
    },
    props: {
        activePatient: {
            type: Object as () => Patient | null,
            required: false,
            default: null,
        },
        pendingPatient: {
            type: Object as () => PendingPatient | null,
            required: false,
            default: null,
        },
    },
})
export default class RelativeItem extends Vue {
    activePatient!: Patient | null;
    pendingPatient!: PendingPatient | null;

    get completeName() {
        if (this.activePatient) return `${this.activePatient.firstName} ${this.activePatient.lastName}`;
        else if (this.pendingPatient) return `${this.pendingPatient.firstName} ${this.pendingPatient.lastName}`;
        return '';
    }

    relativeView() {
        this.$store.dispatch(SET_SELECTED_PATIENT, this.activePatient);
        this.$router.push({
            name: 'page-timeline',
        });
    }
}
