<template>
    <ion-page>
        <TheHeader />
        <ion-content padding>
            <h3 class="ion-text-center mb-3">
                {{ $t("relativesListPage.title") }}
            </h3>

            <RelativeItem
                v-for="pendingPatient in pendingPatients"
                :key="pendingPatient.firstName"
                :pendingPatient="pendingPatient"
            />
            <RelativeItem
                v-for="activePatient in activePatients"
                :key="activePatient.id"
                :activePatient="activePatient"
            />

            <p
                class="ion-text-center"
                v-html="$t('relativesListPage.actionDescription')"
            ></p>
            <div class="ion-text-center">
                <ion-button
                    color="success"
                    fill="outline"
                    class="w-75 bg-white"
                    @click="goTo('page-new-patient')"
                >
                    {{ $t("relativesListPage.actionBtn") }}
                </ion-button>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./RelativesList.ts"></script>
