import { Patient, PendingPatient } from 'client-generator/generated-clients/api-clients';
import { Vue, Options } from 'vue-class-component';
import { mapGetters } from 'vuex';
import RelativeItem from './RelativeItem/RelativeItem.vue';

@Options({
    name: 'RelativesList',
    components: {
        RelativeItem,
    },
    computed: {
        ...mapGetters(['activePatients', 'pendingPatients']),
    },
})
export default class RelativesList extends Vue {
    activePatients!: Patient[];
    pendingPatients!: PendingPatient[];

    goTo(route: string) {
        this.$router.push({
            name: route,
        });
    }
}
